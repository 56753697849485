import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from './router/index';
import routerConfig from './router/config';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import { Login } from './components/Login';
import { UpdatePassword } from './components/UpdatePassword';
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.createRoot(document.getElementById('root')).render(
  <GoogleOAuthProvider clientId="182726304925-7g9e65tqj1clrvc7fn0n07ffrkljnap4.apps.googleusercontent.com">
    <Provider store={store}>
      <Login />
      <UpdatePassword />
      <BrowserRouter>{renderRoutes(routerConfig)}</BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
