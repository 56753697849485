import axios from 'axios';
import { message, Modal } from 'antd';

const instance = axios.create({
  timeout: 180000,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error?.response?.status)
    if (error?.response?.status === 401) {
      message.error('Login expired, please login again');
      localStorage.clear('session_id');
    } else if (error?.response?.status === 403) {
      Modal.warning({
        title: 'Your Plus Quota is not enough.',
        content: (
          <>
            Visit our <a href="/pricing">Pricing</a> page to recharge.
          </>
        ),
        okText: 'Learn More',
        closable: true,
        onOk: () => {
          window.location.href = '/pricing';
        },
      });
    } else if (error?.response?.data?.detail) {
      message.error(String(error.response.data.detail));
    }
    return Promise.reject(error);
  }
);

export default instance;
